


















import {Component, Prop} from "vue-property-decorator";
import AbpBase from "../../../../lib/abpbase";
import DocumentPage from "@/store/entities/jobcontext/documentpage";

@Component
export default class DocumentPageForm extends AbpBase {
    @Prop({default: new DocumentPage()}) page: DocumentPage;
    @Prop({default: 0}) index: number;


    removePage() {
        this.$emit('page-removed', this.index);
    }
}
