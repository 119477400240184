
























































import {Component, Prop, Watch} from "vue-property-decorator";
import AbpBase from "../../../../lib/abpbase";
import DocumentDynamicProperty from "@/store/entities/jobcontext/documentdynamicproperty";
import DynamicLookUp from "@/store/entities/dynamiclookup";

@Component
export default class DynamicProperty extends AbpBase {
    @Prop({default: new DocumentDynamicProperty()}) dynamicProperty: DocumentDynamicProperty;
    @Prop({default: 0}) index: number;
    private types: any;
    private selectValues: Array<DynamicLookUp> = new Array<DynamicLookUp>();

    data() {
        return {
            types: []
        }
    }

    handleRemove(item) {
        this.selectValues = this.selectValues.filter(x => x !== item);
    }

    handleAdd() {
        this.selectValues.push(new DynamicLookUp());
    }

    createDocumentEntity() {
        this.dynamicProperty.values = this.selectValues === null || this.selectValues.length === 0 ? null : JSON.stringify(this.selectValues);
    }

    removeDynamicProperty() {
        this.$emit('remove', this.index);
    }

    async mounted() {
        if (this.dynamicProperty.values){
            this.selectValues = JSON.parse(this.dynamicProperty.values) as Array<DynamicLookUp>;
        }
        this.types = await this.$store.dispatch('document/dynamicPropertiesTypes')
    }

}
