













































import {Component, Prop} from "vue-property-decorator";
import AbpBase from "../../../../lib/abpbase";
import Document from "../../../../store/entities/jobcontext/document";
import Util from "../../../../lib/util";
import DynamicProperty from "@/views/setting/jobcontext/document/dynamicproperty.vue";
import DocumentDynamicProperty from "@/store/entities/jobcontext/documentdynamicproperty";
import DocumentPage from "@/store/entities/jobcontext/documentpage";
import DocumentPageForm from "@/views/setting/jobcontext/document/document-page.vue";

@Component({
    components: {
        DocumentPageForm,
        DynamicProperty,
    }
})
export default class DocumentConfigurationForm extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    private document: Document = new Document();

    visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        } else {
            this.document = Util.extend(true, {}, this.$store.state.document.editDocument);
        }
    }

    addNewPage() {
        let documentPage = new DocumentPage();
        documentPage.number = this.document.pages === null || this.document.pages.length === 0 ? 1 : (Math.max.apply(Math, this.document.pages.map(x => x.number)) + 1);
        if (this.document.pages === null) {
            this.document.pages = new Array<DocumentPage>();
        }
        this.document.pages.push(documentPage)
    }

    pageRemoved(index) {
        this.document.pages.splice(index, 1);
    }

    addDynamicProperty() {
        this.document.dynamicProperties.push(new DocumentDynamicProperty());
    }

    cancel() {
        (this.$refs.documentConfigurationForm as any).resetFields();
        this.$emit('input', false);
    }

    async submit() {
        this.document.dynamicProperties.forEach((x, index) => {
            let ref = 'dynamicProperty' + index.toString();
            (this.$refs[ref] as any)[0].createDocumentEntity()
        });
        await this.$store.dispatch({
            type: 'document/update',
            data: this.document
        });
        (this.$refs.documentConfigurationForm as any).resetFields();
        this.$emit('save-success');
        this.$emit('input', false);
    }

    propertyRemoved(index) {
        this.document.dynamicProperties.splice(index, 1);
    }
}
